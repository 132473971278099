<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      levelTwoAccounts:[],
      branches: [],
      accounts: [],
      Branches: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchModel: "",
      searchBranches: null,
      BranchesSearchMode: false,
      selectedAccounts: null,
      levelThreeAccountId: null,
      levelThreeAccounts: [],
      expanses:[],
      expense:{}
      // appprices:[]
    };
  },
  methods: {
    addAccount() {
      var data = {
        title: "popups.addUsers",
        inputs: [
          { model: "id", type: "text", label: "id" },

          { model: "name", type: "text", label: "name" },

          { model: "created", type: "text", label: "created" },

          { model: "updated", type: "text", label: "updated" },
        ],
        buttons: [
          {
            text: "add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("branches", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.BranchesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("branches/search", { search: this.searchBranches })
        .then((res) => {
          this.branches = res.data;
        });
    },
    selectedNextAccount(account) {
      let type = "Expenses";
      let level = 2;
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account.search,
          level,
          type,
        })
        .then((res) => {
          if (res.status) {
            this.levelTwoAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    cancelappsearchMode() {
      this.searchBranches = "";
      this.BranchesSearchMode = false;
      this.get(this.page);
    },
    getBranches() {
      this.http.get("branches").then((res) => {
        this.branches = res.data;
      });
    },
    editAccount() { },
    deleteBranches(app) {
      var data = {
        title: "popups. deletesms",
        template: `
          <h5>Are you sure you want to delete ?</h5> 
   
        `,
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {
              console.log(app);
              this.http.delete("branches", app.id).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    get(page) {
      console.log(page);
      this.http
        .post(
          "level-three-chart-of-accounts-copy-1/accounts-setup/pagination",
          {
            limit: this.limit,
            page: page,
            subledger_id: 2,
          }
        )
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.accounts = res.data;
        });
    },
    getExpanses() {
      this.http
        .post("emps/get-level-three-expanses", {})
        .then((res) => {
          if (res.status) {
            this.expense = res.data[0];

            console.log(170,this.expense)
          }
        });
    },
    
    selectedAccount(account) {
      let type = "";
      if (account.account_name_en == "Salaries Payable") {
        type = "Liability";
      }
      if (account.account_name_en == "Salaries Tax Payable") {
        type = "Liability";
      }
      if (account.account_name_en == "Insurance Payable") {
        type = "Liability";
      }
      if (account.account_name_en == "Loans") {
        type = "Asset";
      }
      // if (account.account_name_en == "Salary Expense") {
      //   type = "Expense";
      // }
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account.search,
          level: 3,
          type,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    selectedLevelTwoAccount(account) {
      let level = 2;
      let type=""
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account.search,
          level,
          type,
        })
        .then((res) => {
          if (res.status) {
            this.levelThreeAccounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    searchAccounts(account) {
      console.log(account.search);
      this.levelThreeAccountId = account?.search?.split("-")[0];
      console.log(this.levelThreeAccountId);
      this.levelThreeAccounts = [];
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup", {
          leveThreeId: this.levelThreeAccountId,
          id: account.sub_accounts[0]?.sas_id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            account.search = "";
            this.get(this.page);
          }
        });
    },
    searchSingleAccounts(account) {
      console.log(account);
      this.levelThreeAccountId = account?.search?.split("-")[0];
      console.log(this.levelThreeAccountId);
      this.levelThreeAccounts = [];
      this.http
        .post("level-three-chart-of-accounts-copy-1/level-two-account", {
          leveThreeId: this.levelThreeAccountId,
          id: account?.id,
        })
        .then((res) => {
          if (res.status) {
            console.log("done");
            account.search = "";
            this.get(this.page);
          }
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.levelThreeAccounts.filter((account) => {
        return account;
      });
    },
  },
  created() {
    this.get(1);
    this.getExpanses()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menu.menuitems.accounting.subItems.setup')" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-striped table-hover align-middle">
                <thead>
                  <tr class="text-light" style="background-color: #2a3042 !important">
                    <th scope="col">#</th>
                    <th scope="col">{{ $t("accounts_setup.module") }}</th>
                    <th scope="col">{{ $t("accounts_setup.account_name") }}</th>
                    <th scope="col">
                      {{ $t("accounts_setup.selected_account_id") }}
                    </th>
                    <th scope="col">
                      {{ $t("accounts_setup.selected_account") }}
                    </th>
                    <th scope="col">{{ $t("accounts_setup.operations") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, index) in accounts" :key="account">
                    <td>{{ index + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                        ? account.ledger_name
                        : account.ledger_name_en
                      }}
                    </td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                        ? account.account_name
                        : account.account_name_en
                      }}
                    </td>
                    <td>{{ account?.sub_accounts[0]?.code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                        ? account?.sub_accounts[0]?.l3_name
                        : account?.sub_accounts[0]?.l3_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete @keyup="selectedAccount(account)" v-model="account.search"
                          :suggestions="filteredAccounts" @complete="account.search = $event.query" style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          " />
                        <button style="margin-inline-start: 8px" @click="searchAccounts(account)" type="button"
                          class="btn btn-primary">
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr >
                    <td>{{ accounts.length + 1 }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                        ? accounts[0]?.ledger_name
                        : accounts[0]?.ledger_name_en
                      }}
                    </td>
                    <td>
                      {{
                        $i18n.locale == "ar" ? expense?.name : expense?.name_en
                      }}
                    </td>
                    <td>{{ expense?.code }}</td>
                    <td>
                      {{
                        $i18n.locale == "ar"
                          ? expense?.l3_name
                          : expense?.l3_name_en
                      }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <AutoComplete
                          @keyup="selectedLevelTwoAccount(expense)"
                          v-model="expense.search"
                          :suggestions="filteredAccounts"
                          @complete="expense.search = $event.query"
                          style="
                            color: #333 !important;
                            border-radius: 10px !important;
                          "
                        />
                        <button
                          style="margin-inline-start: 8px"
                          @click="searchSingleAccounts(expense)"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("popups.edit") }}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <!-- Button trigger modal -->

            <!--   Apps  Pagination     -->
            <ul class="pagination pagination-rounded justify-content-center mb-2">
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
                <a class="page-link" href="javascript: void(0);" @click="get(p)">{{ p }}</a>
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!--end-->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
